const SpinnerComponent = () => {

    const theme =  JSON.parse(localStorage.getItem('skin'))
    const appLogoImage = theme === 'dark' ? 'logo_letter_white.svg' : 'logo_letter_blue.svg'
    const logo = require(`@src/assets/images/logo/${appLogoImage}`).default

    return (
    <div className='fallback-spinner app-loader'>
      <img className='fallback-logo' src={logo} alt='logo' />
    </div>
  )
}

export default SpinnerComponent
