// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'
import users from '@src/views/users/store'
import profiles from '@src/views/profile/store'
import menus from '@src/views/menu/store'
import call from '@src/views/call/store'
import campaign from '@src/views/campaign/store'
import contact from '@src/views/contact/store'
import reportCall from '@src/views/reportCall/store'
import callLog from '@src/views/callLog/store'
import meta from '@src/views/meta/store'

const rootReducer = {
    navbar, 
    layout,
    auth,
    users,
    profiles,
    menus,
    call,
    campaign,
    contact,
    reportCall,
    callLog,
    meta
}

export default rootReducer
