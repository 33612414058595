// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('meta/getData', async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/meta/list`)
    return {
      data: response.data
    }
})

export const addMeta = createAsyncThunk('meta/insert', async (data, { dispatch }) => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/meta/add`, data)
    
    if (response.data.code === 1) {
        toast.success('Event success')
    } else {
        toast.error('Error')
    }

    dispatch(getData())
})

export const updateMeta = createAsyncThunk('meta/update', async (data, { dispatch }) => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/meta/update`, data)
    
    if (response.data.code === 1) {
        toast.success('Event success')
    } else {
        toast.error('Error')
    }
    dispatch(getData())
})

export const appProfilesSlice = createSlice({
    name: 'meta',
    initialState: {
      data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
            }
        )
    }
})
  
export default appProfilesSlice.reducer