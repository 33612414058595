// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('campaign/getData', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/campaign/list`, {params})
   return {
      params,
      data: response.data.content,
      totalPages: response.data.totalPages
   }
})

export const getCampaign = createAsyncThunk('campaign/getCampaign', async id => {
   let response = {}
   if (id > 0) {
      response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/campaign/${id}/edit`)
      return response.data
   } else return response
})

export const getUserRecruitment = createAsyncThunk('campaign/getUserRecruitment', async () => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/user/recruitment`)
   return {
      dataUserRecruitment: response.data
   }
})

export const getListProtocol = createAsyncThunk('campaign/getListProtocol', async () => {
   let response = {}
   response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/campaign/protocolList`)
   return {
      dataListProtocol: response.data
   }
})

export const addCampaign = createAsyncThunk('campaign/addCampaign', async (CreateCampaign, {dispatch, getState}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/campaign/create`, CreateCampaign)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().campaign.params))
   await dispatch(getAllData())
   return CreateCampaign
})

export const updateCampaign = createAsyncThunk('campaign/addUpdate', async (UpdateCampaign, {dispatch, getState}) => {
   const response = await axios.put(`${process.env.REACT_APP_BASE_API}/api/campaign/${UpdateCampaign.id}/update`, UpdateCampaign)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().campaign.params))
   return UpdateCampaign
})

export const listContactForCampaign = createAsyncThunk('campaign/listContactForCampaign', async campaignId => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/contact/${campaignId}`)
   return {
      dataListContact: response.data
   }
})

export const deleteCampaign = createAsyncThunk('campaign/deleteCampaign', async (id, {dispatch, getState}) => {
   const response = await axios.delete(`${process.env.REACT_APP_BASE_API}/api/campaign/${id}/delete`)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().campaign.params))
   return id
})

/** Request for Setup */
export const getDataCampaignSetup = createAsyncThunk('campaign/getDataCampaignSetup', async id => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/campaignSetup/${id}/edit`)
   return {
      dataSetup: response.data
   }
})

export const addCampaignSetup = createAsyncThunk('campaign/addCampaignSetup', async (CreateCampaignSetup, {
   dispatch,
   getState
}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/campaignSetup/create`, CreateCampaignSetup)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const campaignId = getState().campaign.selectedCampaign.id
   await dispatch(getDataCampaignSetup(campaignId))

   return CreateCampaignSetup
})

// ** Upload contact
export const uploadContact = createAsyncThunk('campaign/uploadContact', async (UploadFile, {
   dispatch,
   getState
}) => {

   const uniqueId = Math.random().toString(16).slice(2)
   const campaignId = getState().campaign.selectedCampaign.id

   const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/contact/${campaignId}/upload`, UploadFile, {
      headers: {
         'Content-Type': `multipart/form-data; boundary=${uniqueId}`
      }
   })

   if (response.data.length === 0) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(listContactForCampaign(campaignId))

   return {
      uploadContactListError: response.data
   }
})

export const findContact = createAsyncThunk('campaign/findContact', async FindContact => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/contact/find`, FindContact)

   return {
      dataFind: response.data
   }
})

export const addContact = createAsyncThunk('campaign/addContact', async (AddContact, {dispatch, getState}) => {
   const campaignId = getState().campaign.selectedCampaign.id
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/contact/${campaignId}/assocContactWithCampaign`, AddContact)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }
   await dispatch(listContactForCampaign(getState().campaign.selectedCampaign.id))

   return AddContact
})

export const addRecruiterForCall = createAsyncThunk('campaign/addRecruiterForCall', async (info, {dispatch, getState}) => {
   const campaignId = getState().campaign.selectedCampaign.id
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/campaign/addRecruiterForCall/${campaignId}/${info.data.recruiterId}`, info.data.list)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }
   await dispatch(listContactForCampaign(getState().campaign.selectedCampaign.id))

   return info
})

export const appCampaignsSlice = createSlice({
   name: 'campaign',
   initialState: {
      data: [],
      total: 1,
      params: {},
      allData: [],
      selectedCampaign: [],
      dataSetup: [],
      dataFind: [],
      dataListContact: [],
      dataListProtocol: [],
      dataUserRecruitment: [],
      uploadContactListError: []
   },
   reducers: {},
   extraReducers: builder => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
         })
         .addCase(getCampaign.fulfilled, (state, action) => {
            state.selectedCampaign = action.payload
         })
         .addCase(getDataCampaignSetup.fulfilled, (state, action) => {
            state.dataSetup = action.payload.dataSetup
         })
         .addCase(findContact.fulfilled, (state, action) => {
            state.dataFind = action.payload.dataFind
         })
         .addCase(listContactForCampaign.fulfilled, (state, action) => {
            state.dataListContact = action.payload.dataListContact
         })
         .addCase(getListProtocol.fulfilled, (state, action) => {
            state.dataListProtocol = action.payload.dataListProtocol
         })
         .addCase(getUserRecruitment.fulfilled, (state, action) => {
            state.dataUserRecruitment = action.payload.dataUserRecruitment
         })
         .addCase(uploadContact.fulfilled, (state, action) => {
            state.uploadContactListError = action.payload.uploadContactListError
         })
   }
})

export default appCampaignsSlice.reducer
