// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('menu/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/menu/list`, {params})
  return {
    params,
    data: response.data.content,
    totalPages: response.data.totalPages
  }
})

export const appMenusSlice = createSlice({
  name: 'menus',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedMenu: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appMenusSlice.reducer
