// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('report/callLog', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/callLog/list`, {params})
   return {
      params,
      data: response.data.content,
      totalPages: response.data.totalPages
   }
})

export const appReportScheduleSlice = createSlice({
   name: 'callLog',
   initialState: {
      data: [],
      total: 0,
      params: {}
   },
   reducers: {},
   extraReducers: builder => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
         })
   }
})

export default appReportScheduleSlice.reducer