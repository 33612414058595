// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('call/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/call/list`, {params})
  return {
    params,
    data: response.data.content,
    totalPages: response.data.totalPages
  }
})

export const getCalledContactList = createAsyncThunk('call/calledContactList', async params => {
  
  const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/call/calledContactList`, params)

  return {
    paramsCalledContactList: params,
    calledContactListCall: response.data
  }
})

export const getCall = createAsyncThunk('call/getCall', async (params, { dispatch }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/call/init`, params)
  
  await dispatch(getCalledContactList(params))
  
  return {
    paramsEdit: params,
    selectedCall: response.data
  }

})

export const updateCall = createAsyncThunk('call/addUpdate', async (data, { dispatch, getState }) => {
  
  const response = await axios.put(`${process.env.REACT_APP_BASE_API}/api/call/${data.info.callId}/update`, data.info)

  if (parseInt(response.data.code) === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  if (data.mode) {

    const userId = parseInt(getState().call.paramsEdit.userId)
    const campaignId = parseInt(getState().call.paramsEdit.campaignId)

    await dispatch(getCall({userId, campaignId}))
  }
  return data.info
})

export const deleteCall = createAsyncThunk('call/deleteCall', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/call/callDelete/${id}`)

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().call.params))
  return id
})

export const getDataCallsToMake = createAsyncThunk('call/callsToMake', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/call/callsToMake`, {params})
  return {
     paramsCallsToMake: params,
     dataCallsToMake: response.data.content,
     totalPagesCallsToMake: response.data.totalPages
  }
})

export const appCallsSlice = createSlice({
  name: 'call',
  initialState: {
    data: [],
    total: 1,
    params: {},
    paramsEdit: {},
    allData: [],
    selectedCall: [],
    paramsCalledContactList: [],
    calledContactListCall: [],
    totalPagesCallsToMake:1,
    dataCallsToMake: [],
    paramsCallsToMake: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCall.fulfilled, (state, action) => {
         state.selectedCall = action.payload.selectedCall
         state.paramsEdit = action.payload.paramsEdit
      })
      .addCase(getCalledContactList.fulfilled, (state, action) => {
        state.calledContactListCall = action.payload.calledContactListCall
        state.paramsCalledContactList = action.payload.paramsCalledContactList
      })
      .addCase(getDataCallsToMake.fulfilled, (state, action) => {
        state.paramsCallsToMake = action.payload.paramsCallsToMake
        state.dataCallsToMake = action.payload.dataCallsToMake
        state.totalPagesCallsToMake = action.payload.totalPagesCallsToMake
      })
  }
})

export default appCallsSlice.reducer
