// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('contact/getData', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/contact/list`, {params})
   return {
      params,
      data: response.data.content,
      totalPages: response.data.totalPages
   }
})

export const getContact = createAsyncThunk('contact/getContact', async id => {
   let response = {}
   if (id > 0) {
      response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/contact/${id}/edit`)
      return response.data
   } else return response
})

export const addContact = createAsyncThunk('contact/addContact', async (CreateContact, {dispatch, getState}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/contact/create`, CreateContact)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().contact.params))
   await dispatch(getAllData())
   return CreateContact
})

export const updateContact = createAsyncThunk('contact/addUpdate', async (UpdateContact, {dispatch, getState}) => {
   const response = await axios.put(`${process.env.REACT_APP_BASE_API}/api/contact/${UpdateContact.id}/update`, UpdateContact)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().contact.params))
   return UpdateContact
})

export const deleteContact = createAsyncThunk('contact/deleteContact', async (id, {dispatch, getState}) => {
   const response = await axios.delete(`${process.env.REACT_APP_BASE_API}/api/contact/${id}/delete`)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().contact.params))
   return id
})


/** Request for Medicine */
export const getDataContactMedicine = createAsyncThunk('contact/getDataContactMedicine', async contactId => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/contactMedicine/${contactId}/list`)
   return {
      dataMedicine: response.data.content
   }
})

export const addContactMedicine = createAsyncThunk('contact/addContactMedicine', async (CreateContactMedicine, {
   dispatch,
   getState
}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/contactMedicine/create`, CreateContactMedicine)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const contactId = getState().contact.selectedContact.id === undefined ? CreateContactMedicine[0]?.contactId : getState().contact.selectedContact.id
   await dispatch(getDataContactMedicine(contactId))
   return CreateContactMedicine
})

export const deleteContactMedicine = createAsyncThunk('contact/deleteContactMedicine', async (data, {dispatch}) => {
   const response = await axios.delete(`${process.env.REACT_APP_BASE_API}/api/contactMedicine/${data.id}/delete`)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }
   
   await dispatch(getDataContactMedicine(data.contactId))
   
   return data.id
})

/** Request for Diseases */
export const getDataContactDiseases = createAsyncThunk('contact/getDataContactDiseases', async contactId => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/contactDiseases/${contactId}/list`)
   return {
      dataDiseases: response.data.content
   }
})

export const addContactDiseases = createAsyncThunk('contact/addContactDiseases', async (CreateContactDiseases, {
   dispatch,
   getState
}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/contactDiseases/create`, CreateContactDiseases)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const contactId = getState().contact.selectedContact.id === undefined ? CreateContactDiseases[0]?.contactId : getState().contact.selectedContact.id
   await dispatch(getDataContactDiseases(contactId))
   return CreateContactDiseases
})

export const deleteContactDiseases = createAsyncThunk('contact/deleteContactDiseases', async (data, {dispatch}) => {
   const response = await axios.delete(`${process.env.REACT_APP_BASE_API}/api/contactDiseases/${data.id}/delete`)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }
   await dispatch(getDataContactDiseases(data.contactId))
   
   return data.id
})

/** Note of contact */
export const getDataContactNote = createAsyncThunk('contact/getDataContactNote', async contactId => {
   if (contactId === undefined) {
      return {
         dataNote: []
      }
   }

   const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/contactNote/${contactId}/list`)
   return {
      dataNote: response.data.content
   }
})

export const addContactNote = createAsyncThunk('contact/addContactNote', async (CreateContactNote, {
   dispatch,
   getState
}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/contactNote/create`, CreateContactNote)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const contactId = getState().contact.selectedContact.id === undefined ? CreateContactNote[0]?.contactId : getState().contact.selectedContact.id
   await dispatch(getDataContactNote(contactId))
   return CreateContactNote
})

export const deleteContactNote = createAsyncThunk('contact/deleteContactNote', async (data, {dispatch}) => {
   
   const response = await axios.delete(`${process.env.REACT_APP_BASE_API}/api/contactNote/${data.id}/delete`)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }
   await dispatch(getDataContactNote(data.contactId))
   
   return data.id
})

/** Document of contact */
export const getDataContactDocument = createAsyncThunk('contact/getDataContactDocument', async contactId => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/contactDocument/${contactId}/list`)
   return {
      dataDocument: response.data.content
   }
})

export const addContactDocument = createAsyncThunk('contact/addContactDocument', async (CreateContactDocument, {
   dispatch,
   getState
}) => {
   const uniqueId = Math.random().toString(16).slice(2)

   const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/contactDocument/create`, CreateContactDocument, {
      headers: {
         'Content-Type': `multipart/form-data; boundary=${uniqueId}`
      }
   })

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const contactId = getState().contact.selectedContact.id === undefined ? CreateContactDocument.contactId : getState().contact.selectedContact.id
   await dispatch(getDataContactDocument(contactId))
   return CreateContactDocument
})

export const deleteContactDocument = createAsyncThunk('contact/deleteContactDocument', async (data, { dispatch }) => {
   const response = await axios.delete(`${process.env.REACT_APP_BASE_API}/api/contactDocument/${data.id}/delete`)

   if (response.data.code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getDataContactDocument(data.contactId))
   return data.id
})

export const appContactsSlice = createSlice({
   name: 'contact',
   initialState: {
      data: [],
      total: 1,
      params: {},
      allData: [],
      selectedContact: [],
      dataMedicine: [],
      dataDiseases: [],
      dataNote: [],
      dataDocument: []
   },
   reducers: {},
   extraReducers: builder => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
         })
         .addCase(getContact.fulfilled, (state, action) => {
            state.selectedContact = action.payload
         })
         .addCase(getDataContactMedicine.fulfilled, (state, action) => {
            state.dataMedicine = action.payload.dataMedicine
         })
         .addCase(getDataContactDiseases.fulfilled, (state, action) => {
            state.dataDiseases = action.payload.dataDiseases
         })
         .addCase(getDataContactNote.fulfilled, (state, action) => {
            state.dataNote = action.payload.dataNote
         })
         .addCase(getDataContactDocument.fulfilled, (state, action) => {
            state.dataDocument = action.payload.dataDocument
         })
   }
})

export default appContactsSlice.reducer
