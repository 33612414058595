import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        
        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        console.log("error", error)
        const { response } = error
        // const { config, response } = error
        // const originalRequest = config

        if (response.status === 401) {
          this.logoutError()
          // if (!this.isAlreadyFetchingAccessToken) {
          //   this.isAlreadyFetchingAccessToken = true
            
          //   this.refreshToken().then(r => {
          //     this.isAlreadyFetchingAccessToken = false

          //     this.setToken(r.data.accessToken)
          //     this.setRefreshToken(r.data.refreshToken)
          //     this.onAccessTokenFetched(r.data.accessToken)  
          //   })  
          // }

          // const retryOriginalRequest = new Promise(resolve => {
          //   this.addSubscriber(accessToken => {
          //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          //     resolve(axios(originalRequest))
          //   })
          // })
          
          // return retryOriginalRequest
        
        } else if (response && response.status === 408) {
          this.logout()
        } else if (response && response.status === 500) {
          this._error()
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(args) {
    // const json = JSON.stringify(args)
    return axios.post(this.jwtConfig.loginEndpoint, args)
  }

  logoutError() {
    localStorage.removeItem('userData')
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)

    location.href = '/login'
  }

  logout(accessToken) {
    return axios.post(this.jwtConfig.logoutEndpoint, null, {
      headers: {
         Authorization: `Bearer ${accessToken}`
      }
   })
  }

  _error() {
    // location.href = '/error'
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    const userData = JSON.parse(localStorage.getItem(this.jwtConfig.storageUserData))
    return axios.post(this.jwtConfig.refreshEndpoint, {
      Request: {
        RefreshToken: {},
        UserId: userData.id
      }
    })
  }

  setUserData(value) {
    if (value) {
      localStorage.setItem(this.jwtConfig.storageUserData, JSON.stringify(value))
    }
  }

  getUserData() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.storageUserData))
  }

  setListPermissions(value) {
    if (value) {
      localStorage.setItem("listPermissions", JSON.stringify(value))
    }
  }

  getListPermissions() {
    return JSON.parse(localStorage.getItem("listPermissions"))
  }

}
